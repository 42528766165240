import React from "react";

import Layout from "../components/layout";

const FigmaTestPage = ({ data, location: { pathname } }) => (
    <section className="p-0 h-100">
      <div className="vh-100">
        <iframe
          style={{ border: "0px solid rgba(255, 255, 255, 1);" }}
          width="100%"
          height="100%"
          src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F6RvkrzOKdNDCltEEChjYpy%2FDevStats-Handoff%3Fpage-id%3D1%253A4%26node-id%3D802%253A697%26viewport%3D501%252C48%252C0.29%26scaling%3Dcontain%26hotspot-hints%3D0%26hide-ui%3D1"
        allowfullscreen>
        </iframe>
      </div>
    </section>
);

export default FigmaTestPage;
